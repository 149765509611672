import React, { useEffect } from "react"
import "./CarCatalogueScreen.scss"
import InnerHeader from "../../components/header/InnerHeader"
import Button from "@mui/material/Button"
import BrandList from "../../components/catalogue/brand/BrandList"
import BrandEdit from "../../components/catalogue/brand/BrandEdit"
import ModelList from "../../components/catalogue/model/ModelList"
import SideBarDrawer from "../../components/SideMenu/SideBarDrawer"
import { useDispatch } from "react-redux"
import { BRAND_DETAIL_RESET } from "../../state/constants/brandConstants"
import { getFilterOptions } from "../../state/actions/carMpActions"
// import ShareLinkPopup from "../../components/catalogue/ShareLinkPopup"

const CarCatalogueScreen = () => {
  const [show, setShow] = React.useState(false)
  const [title, setTitle] = React.useState("")
  const [type, setType] = React.useState("")
  const [data, setData] = React.useState("")
  const [reload, setReload] = React.useState({})
  // const [genLinkPopup, setGenLinkPopup] = useState("")

  const toggleDrawer = (open) => (event) => {
    setShow(open)
  }

  const dispatch = useDispatch()

  const addBrandHandler = () => {
    dispatch({ type: BRAND_DETAIL_RESET })
  }

  useEffect(() => {
    dispatch(getFilterOptions())
    dispatch({ type: BRAND_DETAIL_RESET })
  }, [dispatch])

  const openSlider = (type, pageName, params = [] || {}) => {
    setTitle(pageName)
    setType(type)
    setData(params)
    setShow(true)
  }

  const generateLinkHandler = () => {
    // setGenLinkPopup(true)
  }

  return (
    <div className="dashboard">
      <SideBarDrawer
        show={show}
        toggleDrawer={toggleDrawer}
        title={title}
        type={type}
        data={data}
        setReload={setReload}
      />
      <InnerHeader title="Car Database" className="inner-header">
        <div className="buttons-wrapper flex aic">
          <Button
            variant="contained"
            className="button"
            onClick={() => addBrandHandler()}
          >
            Add Brand
          </Button>
          <Button
            variant="contained"
            className="button"
            onClick={() => openSlider("model", "Add Model")}
          >
            Add Model
          </Button>
          <Button
            variant="contained"
            className="button"
            onClick={() => generateLinkHandler()}
          >
            Generate Link
          </Button>
        </div>
      </InnerHeader>
      <div className="body-container">
        <div className="r1-container">
          <h2 className="title">Brands</h2>
          <div className="r1 flex">
            <div className="c1">
              <BrandList />
            </div>
            <div className="c2">
              <BrandEdit />
            </div>
          </div>
        </div>
        <div className="r2-container">
          <h2 className="title">Models</h2>

          <ModelList
            openSlider={openSlider}
            reload={reload}
            setReload={setReload}
          />
        </div>
      </div>
      {/* <ShareLinkPopup trigger={genLinkPopup} setTrigger={setGenLinkPopup} /> */}
    </div>
  )
}

export default CarCatalogueScreen
