import React, { useEffect, useState } from "react"
import dashboard from "../../images/sidebar/dashboard.svg"
import carmp from "../../images/sidebar/carct.svg"
import seller from "../../images/sidebar/carmp.svg"
import auction from "../../images/sidebar/icon_auction.svg"
import checklist from "../../images/sidebar/checklist.svg"
import users from "../../images/sidebar/users.svg"
import settings from "../../images/sidebar/settings.svg"
import database from "../../images/sidebar/database.svg"
import events from "../../images/sidebar/events.svg"
// import opportunity from "../../images/sidebar/opportunity.svg"
import links from "../../images/sidebar/bg_link_generator.svg"
import ads from "../../images/sidebar/bg_ads.svg"
import trims from "../../images/sidebar/bg_trims_manager.svg"
import logs from "../../images/sidebar/bg_logs.svg"
import "./Sidebar.scss"
import trend from "../../images/sidebar/trend.svg"
import { useSelector } from "react-redux"
import oval from "../../assets/icon/sidebar/oval.svg"
import { Link } from "react-router-dom"
import { hasResourcePermission } from "../../utils/utilsFunctions"
import useAuthUser from "../../state/customHooks/user/useAuthUser"

const SideBar = ({ time }) => {
  const [user] = useAuthUser()
  const [active, setActive] = useState("carct")

  const [buffer, setBuffer] = useState([])
  const [alert, setAlert] = useState(false)

  const notifications = useSelector(
    (state) => state.notifications.notificationsChange
  )
  const { notify } = notifications

  useEffect(() => {
    if (buffer.length !== notify.length) {
      setAlert(true)
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = "/favicon.ico"
    } else {
      setAlert(false)
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement("link")
        link.rel = "icon"
        document.getElementsByTagName("head")[0].appendChild(link)
      }
      link.href = "/faviconna.ico"
    }
    setBuffer(notify)
  }, [notify])

  return (
    <>
      <div className="side-bar">
        {/* Dashboard */}

        <Link
          to={`/dashboard`}
          className={
            active === "Dashboard"
              ? "icon-button flex jcc aic active"
              : "icon-button flex jcc aic"
          }
          onClick={() => {
            setActive("Dashboard")
          }}
        >
          <img src={dashboard} alt="icon" />
        </Link>
        {/* Market Place / Car List*/}
        {hasResourcePermission(user, "car") && (
          <Link
            to={`/car/list?activeTab=All&status=PENDING&pageNumber=1&keywordType=BRAND&keyword=`}
            className={
              active === "carmp"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("carmp")
              setAlert(false)
              let link = document.querySelector("link[rel~='icon']")
              if (!link) {
                link = document.createElement("link")
                link.rel = "icon"
                document.getElementsByTagName("head")[0].appendChild(link)
              }
              link.href = "/faviconna.ico"
            }}
          >
            <img src={carmp} alt="icon" />
            {alert && <img src={oval} alt="" className="dot" />}
          </Link>
        )}

        {/* Market Place / Car List*/}
        {hasResourcePermission(user, "seller") && (
          <Link
            to={
              "/seller/list?activeTab=All&sellerType=dealerships&keyword=&tags=&status=ACTIVE&pageNumber=1"
            }
            className={
              active === "seller"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("seller")
            }}
          >
            <img src={seller} alt="icon" />
          </Link>
        )}
        {/* Trims Manager */}
        {hasResourcePermission(user, "new-car") && (
          <Link
            to={"/trim/dashboard"}
            className={
              active === "checklist"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("checklist")
            }}
          >
            <img src={trims} alt="icon" />
          </Link>
        )}
        {/* Users */}
        {hasResourcePermission(user, "user") && (
          <Link
            to={"/user/list"}
            className={
              active === "users"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("users")
            }}
          >
            <img src={users} alt="icon" />
          </Link>
        )}
        {/* Car Database */}
        {hasResourcePermission(user, "catalogue") && (
          <Link
            to={"/catalogue"}
            className={
              active === "database"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("database")
            }}
          >
            <img src={database} alt="icon" />
          </Link>
        )}
        {/* Logs */}
        {hasResourcePermission(user, "logs") && (
          <Link
            to={"/logs"}
            className={
              active === "logs"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("logs")
            }}
          >
            <img src={logs} alt="icon" />
          </Link>
        )}
        {/* Advertisements */}
        {hasResourcePermission(user, "banners") && (
          <Link
            to={"/events/list"}
            className={
              active === "events"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("events")
            }}
          >
            <img src={events} alt="icon" />
          </Link>
        )}
        {/* Events */}
        {hasResourcePermission(user, "event") && (
          <Link
            to={"/sale/list?status=SCHEDULED&pageNumber=1"}
            className={
              active === "sale"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("sale")
            }}
          >
            <img src={ads} alt="icon" />
          </Link>
        )}
        {/* Auctions */}
        {hasResourcePermission(user, "auction") && (
          <Link
            to={"/auction/list"}
            className={
              active === "auction"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("auction")
            }}
          >
            <img src={auction} alt="icon" />
          </Link>
        )}
        {/* Opportunities */}
        {hasResourcePermission(user, "opportunities") && (
          <Link
            to={"/opportunity/list"}
            className={
              active === "opportunity"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("opportunity")
            }}
          >
            <img src={checklist} alt="icon" />
          </Link>
        )}
        {/* Link generator  */}
        {hasResourcePermission(user, "links") && (
          <Link
            to={"/link?pageNumber=1"}
            className={
              active === "link"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("link")
            }}
          >
            <img src={links} alt="icon" />
          </Link>
        )}
        {/* Price trends */}
        {hasResourcePermission(user, "trends") && (
          <Link
            to={"/trends"}
            className={
              active === "trend"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("trend")
            }}
          >
            <img src={trend} alt="icon" />
          </Link>
        )}
        {/* Settings */}
        {hasResourcePermission(user, "catalogue") && (
          <Link
            to={"/specification/dashboard"}
            className={
              active === "settings"
                ? "icon-button flex jcc aic active"
                : "icon-button flex jcc aic"
            }
            onClick={() => {
              setActive("spec")
            }}
          >
            <img src={settings} alt="icon" />
          </Link>
        )}
      </div>
    </>
  )
}

export default SideBar
