import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import CustomTabs from "../../components/common/CustomTabs"
import InnerHeader from "../../components/header/InnerHeader"
import InnerContainer from "../../components/utils/InnerContainer"
import "./SaleListScreen.scss"
import { DropDownMenu } from "../../components/common/DropDownMenu"
import AuctionList from "../../components/auctionscreen/AuctionList"
import Message from "../../components/utils/Message"
import { LinearProgress } from "@mui/material"
import useSaleList from "../../state/customHooks/sale/useSaleList"
import SaleList from "../../components/salescreen/SaleList"

const SaleListScreen = () => {
  const [activeTab, setActiveTab] = useState("scheduled")

  const [searchParams, setSearchParams] = useSearchParams()

  const statusQuery = searchParams.get("status")
  const pageNumberQuery = searchParams.get("pageNumber")

  const [queryObject, setQueryObject] = useState({})

  useEffect(() => {
    setQueryObject({ status: statusQuery, pageNumber: pageNumberQuery })
  }, [])

  useEffect(() => {
    setSearchParams({ ...queryObject })
  }, [queryObject])

  const navigate = useNavigate()

  const [pageNumber, setPageNumber] = useState(pageNumberQuery)

  const [loading, error, sales, counts, page, pages, count] =
    useSaleList(queryObject)

  const [countBuffer, setCountBuffer] = useState({})

  useEffect(() => {
    !loading && setCountBuffer(counts)
  }, [counts])

  const dealershipCreateHandler = () => {
    navigate(`/sale/create?type=dealerships`)
  }
  const companyCreateHandler = () => {
    navigate(`/sale/create?type=companies`)
  }
  const officialBrandCreateHandler = () => {
    navigate(`/sale/create?type=officialBrands`)
  }

  const menuItem = [
    {
      title: "Dealership",
      action: dealershipCreateHandler,
    },
    {
      title: "Company",
      action: companyCreateHandler,
    },
    {
      title: "Official Brand",
      action: officialBrandCreateHandler,
    },
  ]

  const carStatusTab = [
    { tabName: "scheduled", count: countBuffer?.scheduled || 0 },
    { tabName: "active", count: countBuffer?.active || 0 },
    { tabName: "closed", count: countBuffer?.closed || 0 },
    { tabName: "canceled", count: countBuffer?.canceled || 0 },
    { tabName: "archived", count: countBuffer?.archived || 0 },
  ]

  const AuctionTabsBody = [
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <SaleList
          saleList={sales}
          page={page}
          pages={pages}
          count={count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          queryObject={queryObject}
          setQueryObject={setQueryObject}
        />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <SaleList
          saleList={sales}
          page={page}
          pages={pages}
          count={count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          queryObject={queryObject}
          setSearchParams={setSearchParams}
        />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <SaleList
          saleList={sales}
          page={page}
          pages={pages}
          count={count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          queryObject={queryObject}
          setSearchParams={setSearchParams}
        />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <SaleList
          saleList={sales}
          page={page}
          pages={pages}
          count={count}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      )}
    </>,
    <>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Message variant="error">{error} </Message>
      ) : (
        <AuctionList
          auctionList={sales}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      )}
    </>,
  ]

  const tabClickHandler = (click) => {
    setActiveTab(click.tabName)
    setQueryObject((prev) => {
      return { ...prev, status: click.tabName, pageNumber: 1 }
    })
  }

  return (
    <div className="sale-screen">
      <InnerHeader title="Events" className="inner-header">
        <DropDownMenu type="inner-header" menuItem={menuItem} title="Create" />
      </InnerHeader>

      <InnerContainer>
        <CustomTabs
          tabs={carStatusTab}
          tabsBody={AuctionTabsBody}
          activeTab={activeTab}
          tabClickHandler={tabClickHandler}
        />
      </InnerContainer>
    </div>
  )
}

export default SaleListScreen
