import React, { useMemo } from "react"
import "./NewCarTrimsDashboard.scss"
import InnerHeader from "../../components/header/InnerHeader"
import InnerHeaderButton from "../../components/utils/Button"
import InnerContainer from "../../components/utils/InnerContainer"
import CustomTabs from "../../components/common/CustomTabs"
import {
  useTrimListByFilter,
  useTrimYearListDistinct,
} from "../../state/customHooks/carct/useTrimList"
import TrimsList from "../../components/newcartrimdashboardscreens/TrimsList"
import TrimCreateEditForm from "../../components/newcartrimdashboardscreens/TrimCreateEditForm"
import { useDispatch } from "react-redux"
import { TRIM_DETAIL_RESET } from "../../state/constants/trimConstants"
import MSideDrawer from "../../components/utils/MSideDrawer"
import useTrimCreate from "../../state/customHooks/carct/useTrimCreate"
import useTrimDelete from "../../state/customHooks/carct/useTrimDelete"

const NewCarTrimsDashboard = () => {
  const [activeTab, setActiveTab] = React.useState("PENDING")
  const [activeYear, setActiveYear] = React.useState(2024)
  const [trim, setTrim] = React.useState({})
  const [serverFilters, setServerFilters] = React.useState({})
  const [pageNumber, setPageNumber] = React.useState(1)
  const [isUpdated, setIsUpdated] = React.useState(false)
  const [modalShow, setModalShow] = React.useState(false)

  const [, , successTrimCreate] = useTrimCreate()
  const [, , successTrimDelete] = useTrimDelete()

  const dispatch = useDispatch()

  // Memoize the query object to prevent recreation on each render
  const query = useMemo(
    () => ({
      year: activeYear,
      status: activeTab,
      ...serverFilters[0],
      pageNumber,
    }),

    [
      activeYear,
      activeTab,
      serverFilters,
      pageNumber,
      isUpdated,
      successTrimCreate,
      successTrimDelete,
    ]
  )

  const paginationHandler = (data) => {
    setPageNumber(data + 1)
  }
  const serverFieldHandler = (data) => {
    setServerFilters(data)
  }
  const { years } = useTrimYearListDistinct(query)

  const [loading, error, trims, count] = useTrimListByFilter(query, isUpdated)

  const statusTab = [
    { tabName: "PENDING", count: count?.pending || 0 },
    { tabName: "LISTED", count: count?.listed || 0 },
  ]

  const onYearClick = (year) => {
    setActiveYear(year)
  }

  const tabsBody = [
    <>
      <TrimsList
        years={years}
        onYearClick={onYearClick}
        activeYear={activeYear}
        trims={trims}
        count={count}
        selectedTrim={trim}
        setSelectedTrim={setTrim}
        serverFieldHandler={serverFieldHandler}
        paginationHandler={paginationHandler}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
        loading={loading}
        error={error}
        activeTab={activeTab}
        setModalShow={setModalShow}
      />
    </>,
    <>
      <TrimsList
        years={years}
        onYearClick={onYearClick}
        activeYear={activeYear}
        trims={trims}
        count={count}
        selectedTrim={trim}
        setSelectedTrim={setTrim}
        serverFieldHandler={serverFieldHandler}
        paginationHandler={paginationHandler}
        isUpdated={isUpdated}
        setIsUpdated={setIsUpdated}
        loading={loading}
        error={error}
        activeTab={activeTab}
        setModalShow={setModalShow}
      />
    </>,
  ]

  const tabClickHandler = (click) => {
    setActiveTab(click.tabName)
  }

  const addNewTrimHandler = () => {
    setModalShow(true)
    setTrim({})
    dispatch({ type: TRIM_DETAIL_RESET })
  }

  return (
    <div className="new-car-trims-dashboard">
      <InnerHeader title="Trims Manager" className="inner-header">
        <InnerHeaderButton onClick={addNewTrimHandler} color={"#1c86b8"}>
          Add Trim
        </InnerHeaderButton>
      </InnerHeader>
      <InnerContainer>
        <div className="new-car-trims-dashboard-r1">
          <div className="new-car-trims-dashboard-r1-c1">
            <CustomTabs
              tabs={statusTab}
              tabsBody={tabsBody}
              activeTab={activeTab}
              tabClickHandler={tabClickHandler}
              status
            />
          </div>
        </div>

        <MSideDrawer
          show={modalShow}
          toggleDrawer={setModalShow}
          title="Add/Edit Trim"
          noHeader
        >
          <TrimCreateEditForm trim={trim} />
        </MSideDrawer>
      </InnerContainer>
    </div>
  )
}

export default NewCarTrimsDashboard
